// react , redux import
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { animateScroll as scroll } from 'react-scroll/modules';
// locale component
import Footer from "../src/components/compound/footer";
import Header from "../src/components/compound/header";
import Navbar from "../src/components/compound/navbar";
import MuiDrawer from './components/compound/Drawer';
// mui
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// react icon 
import { BsArrowUp } from 'react-icons/bs';
import RoutesContainer from './Routes';
// locale styles
import SocailContainer from './components/compound/SocailContainer';
import styles from './index.module.scss';

function App() {

  // get locale and direction from redux
  const locale = useSelector(state => state.locale.value.lang)
  const dir = useSelector(state => state.locale.value.dir)

  // get current media query
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  // show and hidden go to up button
  const [visible, setVisible] = useState(false)
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true)
    }
    else if (scrolled <= 300) {
      setVisible(false)
    }
  }
  window.addEventListener('scroll', toggleVisible);

  return (
    <div className={`${styles.App} ${styles[locale]} ${styles[dir]}`}>
      {isTablet ? (
        <MuiDrawer />
      ) : (
        <>
          <Header />
          <Navbar />
        </>
      )}
      <RoutesContainer />
      <Footer />
      <SocailContainer />
      {/* button with gototop function */}
      <button
        className={styles.goToUp}
        id='gototop'
        style={{ display: visible ? 'flex' : 'none' }}
        onClick={() => scroll.scrollToTop()}>
        <BsArrowUp />
      </button>
    </div>
  );
}

export default App;
