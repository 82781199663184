/* eslint-disable jsx-a11y/iframe-has-title */
/** @format */

// React
import React from "react";
// local styles
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import egypt from '../../../assets/contact/egypt.png';
import india from '../../../assets/contact/india.png';
import location from '../../../assets/contact/location-pin.png';
import morocco from '../../../assets/contact/morocco.png';
import phone from '../../../assets/contact/phone-call.png';
import united from '../../../assets/contact/united-states.png';
import facebook from "../../../assets/footer/facebook.png";
import instagram from "../../../assets/footer/instagram.png";
import twitter from "../../../assets/footer/twitter.png";
import youtube from "../../../assets/footer/youtube.png";
import logo from '../../../assets/icons/logo2.png';
import styles from "./index.module.scss";




const Footer = () => {
	const { t } = useTranslation();
	const locale = useSelector(state => state.locale.value.lang)

	return (
		<footer className={styles.container}>
			<div className={styles.wrapper}>
				<section className={styles.sec1}>
					<img className={styles.logo} src={logo} alt='logo' />
					<p>{t('footer.sec1Pragraph')}</p>
					<div className={styles.links}>
						<Link to={`/${locale}/`}>
							<img src={facebook} alt="link" />
						</Link>
						<Link to={`/${locale}/`}>
							<img src={instagram} alt="link" />
						</Link>
						<Link to={`/${locale}/`}>
							<img src={twitter} alt="link" />
						</Link>
						<Link to={`/${locale}/`}>
							<img src={youtube} alt="link" />
						</Link>
					</div>
				</section>
				<section className={styles.sec2}>
					<h3 className={styles.title}>{t(`footer.sec2Title`)}</h3>
					<ul>
						<li>
							<Link to={`${locale}/marble/egypt`}>
								{t(`footer.sec2Link3`)}
							</Link>
						</li>
						<li>
							<Link to={`${locale}/granite/egypt`}>
								{t(`footer.sec2Link4`)}
							</Link>
						</li>
					</ul>
				</section>
				<section className={styles.sec3}>
					<h3 className={styles.title}>{t('footer.sec3Title')}</h3>
					<div style={{ width: '100%' }}>
						<iframe
							width="100%"
							height="170"
							frameborder="0"
							scrolling="no"
							marginheight="0"
							marginwidth="0"
							style={{ borderRadius: '17px' }}
							src="https://maps.google.com/maps?width=100%25&amp;height=200&amp;hl=en&amp;q=N8,%20Morocco+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
						</iframe>
					</div>
					<div className={styles.links}>
						<Link to={`/${locale}/`}>
							<img src={facebook} alt="link" />
						</Link>
						<Link to={`/${locale}/`}>
							<img src={instagram} alt="link" />
						</Link>
						<Link to={`/${locale}/`}>
							<img src={twitter} alt="link" />
						</Link>
						<Link to={`/${locale}/`}>
							<img src={youtube} alt="link" />
						</Link>
					</div>
				</section>
			</div>
			<div className={styles.camponyInfo} data-aos="fade-left">
				<div className={styles.infoItem} >
					<img src={morocco} className={styles.icon} alt="" />
					<div className={styles.data}>
						<a className={styles.upper} href="https://wa.me/+212648488182" target="_blank" rel='noreferrer'>
							<img src={phone} alt="icon" />
							<span>+2126-4848-8182</span>
						</a>
						<a className={styles.upper} href="https://maps.app.goo.gl/wXjc3GhW1nH9skcn8" target="_blank" rel='noreferrer'>
							<img src={location} alt="icon" />
							<span>{t("contact.info.moroccocLocation")}</span>
						</a>
					</div>
				</div>
				<div className={styles.infoItem} >
					<img src={egypt} className={styles.icon} alt="" />
					<div className={styles.data}>
						<a className={styles.upper} href="https://wa.me/+201007025204" target="_blank" rel='noreferrer'>
							<img src={phone} alt="icon" />
							<span>+201007025204 / +20244820496</span>
						</a>
						<a className={styles.upper} href="https://maps.app.goo.gl/t5dik9vsfPwkDjj97" target="_blank" rel='noreferrer'>
							<img src={location} alt="icon" />
							<span>{t("contact.info.egyptLocation")}</span>
						</a>
					</div>
				</div>
				<div className={styles.infoItem} href="https://maps.app.goo.gl/rF8iA4EjHo9MECgCA" target="_blank" rel='noreferrer'>
					<img src={united} className={styles.icon} alt="" />
					<div className={styles.data}>
						<a className={styles.upper} href="https://wa.me/+12127290012" target="_blank" rel='noreferrer'>
							<img src={phone} alt="icon" />
							<span>+12127290012</span>
						</a>
						<a className={styles.upper} href="https://maps.app.goo.gl/rF8iA4EjHo9MECgCA" target="_blank" rel='noreferrer'>
							<img src={location} alt="icon" />
							<span>{t("contact.info.unitedLocation")}</span>
						</a>
					</div>
				</div>
				<div className={styles.infoItem} >
					<img src={india} className={styles.icon} alt="" />
					<div className={styles.data}>
						<a className={styles.upper} href="https://wa.me/+918510036125" target="_blank" rel='noreferrer'>
							<img src={phone} alt="icon" />
							<span>+918510036125</span>
						</a>
						<a className={styles.upper} href="https://maps.app.goo.gl/28K5rJPJfH4TYSWr7" target="_blank" rel='noreferrer'>
							<img src={location} alt="icon" />
							<span>{t("contact.info.indiaLocation")}</span>
						</a>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
