import { Collapse, makeStyles } from '@material-ui/core';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import styles from './index.module.scss';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
// locale image
import email from '../../../assets/icons/email.png';
import locationImg from '../../../assets/icons/location.png';
import logo from '../../../assets/icons/logo1.png';
import phone from '../../../assets/icons/phone.png';
import whatsapp from '../../../assets/icons/whatsapp.png';
// dispatch action
import { changeLocale } from '../../../redux/Features/locale';


const MuiDrawer = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const classes = useStyles()
  const locale = useSelector(state => state.locale.value.lang)
  const { t } = useTranslation()
  const location = useLocation()

  const arrlinks = [`/${locale}/`, `${locale}/marble/egypt`, `${locale}/granite/egypt`, `${locale}/contact`]
  const arrLinksText = [t("navbar.main"), t("navbar.marble.main"), t("navbar.granite.main"), t("navbar.contact")]

  const dispatch = useDispatch()
  const [language, setLanguage] = useState(locale)
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const navigate = useNavigate()
  // handle change language
  const changeLanguage = (lang) => {
    if (lang === "ar") {
      setLanguage("ar")
      dispatch(changeLocale({ lang: "ar", dir: "rtl" }))
      navigate(`/${lang}${location.pathname.slice(3)}`)
    } else if (lang === "en") {
      setLanguage("en")
      dispatch(changeLocale({ lang: "en", dir: "ltr" }))
      navigate(`/${lang}${location.pathname.slice(3)}`)
    } else if (lang === "fr") {
      setLanguage("fr")
      dispatch(changeLocale({ lang: "fr", dir: "ltr" }))
      navigate(`/${lang}${location.pathname.slice(3)}`)
    }
    setIsDrawerOpen(false)
  }

  useEffect(() => {
    setIsDrawerOpen(false)
  }, [location.pathname])

  const path = arrlinks.filter(a => a.includes(location.pathname.split('/')[2]?.toString().toLowerCase()))
  const activeIndex = arrlinks.findIndex(a => a == path) != -1 ? arrlinks.findIndex(a => a == path) : 0

  return (
    <>
      {
        <div className={styles.container}>
          <IconButton className={classes.btn} size='large' color='inherit' aria-label='logo' onClick={() => setIsDrawerOpen(true)}>
            <MenuIcon />
          </IconButton>
          <div className={styles.logo}>
            <img
              src={logo}
              alt="logo"
              onClick={() => window.location.href = "/"}
            />
          </div>
          <Drawer anchor={locale === 'ar' ? 'right' : 'left'} open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
            <div className={styles.header}>
              <div className={styles.logo}>
                <img src={logo} alt="logo" onClick={() => window.location.href = "/"} />
              </div>
              {/* language component */}
              <div className={styles.languageBtns}>
                {/* change language value when select item */}
                <select className={styles.select} defaultValue={language} onChange={(e) => changeLanguage(e.target.value)}>
                  <option className={language === "ar" ? styles.active : undefined} value="ar">{t("header.arabic")}</option>
                  <option className={language === "en" ? styles.active : undefined} value="en">{t("header.english")}</option>
                  <option className={language === "fr" ? styles.active : undefined} value="fr">{t("header.french")}</option>
                </select>
              </div>
            </div>
            <Box width='240px' textAlign='center' role='presentation'>
              <List>
                {arrLinksText.map((text, index) => (
                  (text !== t("navbar.granite.main") && text !== t("navbar.marble.main")) ? (
                    <NavLink id={`nav_${index}`} key={index} to={arrlinks[index]} className={({ isActive }) => ((isActive || activeIndex == index) ? `${styles.active}` : `${styles.inactive}`)}>
                      <ListItem className={`${styles.listItem} ${styles[locale]}`} button key={text}>
                        <ListItemText primary={text} />
                      </ListItem>
                    </NavLink>
                  ) : text === t("navbar.granite.main") ? (
                    <>
                      <ListItemButton className={`${styles.graniteBtn} ${styles[locale]}`} key={index} onClick={handleClick}>
                        <ListItemText primary={t("navbar.granite.main")} />
                        {open ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                      <Collapse className={`${styles.collapsedMenu} ${styles[locale]}`} in={open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          <NavLink to={`${locale}/granite/egypt`} className={({ isActive }) => (isActive ? `${styles.activeCol}` : `${styles.inactiveCol}`)}>
                            <ListItemText primary={t("navbar.granite.egypt")} />
                          </NavLink>
                          <NavLink to={`${locale}/granite/india`} className={({ isActive }) => (isActive ? `${styles.activeCol}` : `${styles.inactiveCol}`)}>
                            <ListItemText primary={t("navbar.granite.india")} />
                          </NavLink>
                        </List>
                      </Collapse>
                    </>
                  ) : text === t("navbar.marble.main") ? (
                    <>
                      <ListItemButton className={`${styles.graniteBtn} ${styles[locale]}`} key={index} onClick={handleClick}>
                        <ListItemText primary={t("navbar.marble.main")} />
                        {open ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                      <Collapse className={`${styles.collapsedMenu} ${styles[locale]}`} in={open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          <NavLink to={`${locale}/marble/egypt`} className={({ isActive }) => (isActive ? `${styles.activeCol}` : `${styles.inactiveCol}`)}>
                            <ListItemText primary={t("navbar.marble.egypt")} />
                          </NavLink>
                          <NavLink to={`${locale}/marble/india`} className={({ isActive }) => (isActive ? `${styles.activeCol}` : `${styles.inactiveCol}`)}>
                            <ListItemText primary={t("navbar.marble.india")} />
                          </NavLink>
                        </List>
                      </Collapse>
                    </>
                  ) : null
                ))}
              </List>
              <Divider />
              {/* <ul></ul> */}
            </Box>
            <div className={styles.camponyInfo}>
              <a className={`${styles.infoItem} ${styles[locale]}`} href="https://maps.app.goo.gl/3d6pSoiSHfpUMNFA8" rel="noreferrer" target="_blank">
                <img src={locationImg} className={styles.icon} alt="" />
                <div className={styles.data}>
                  <span className={styles.upper}>
                    {t("header.location")}
                  </span>
                  <span className={styles.lower}>
                    {t("header.locationText")}
                  </span>
                </div>
              </a>
              <a href="https://wa.me/+212618924414" target="_blank" rel="noreferrer" className={`${styles.infoItem} ${styles[locale]}`}>
                <img src={whatsapp} className={styles.icon} alt="" />
                <div className={styles.data}>
                  <span className={styles.upper}>
                    {t("header.whatsapp")}
                  </span>
                  <span className={styles.lower}>
                    +212618924414
                  </span>
                </div>
              </a>
              <div className={`${styles.infoItem} ${styles[locale]}`}>
                <img src={phone} className={styles.icon} alt="" />
                <div className={styles.data}>
                  <span className={styles.upper}>
                    {t("header.phone")}
                  </span>
                  <span className={styles.lower}>
                    +212618924414
                  </span>
                </div>
              </div>
              <a className={`${styles.infoItem} ${styles[locale]}`} href="mailto:info@lecaire-trading.com">
                <img src={email} className={styles.icon} alt="" />
                <div className={styles.data}>
                  <span className={styles.upper}>
                    {t("header.email")}
                  </span>
                  <span className={styles.lower}>
                    info@lecaire-trading.com
                  </span>
                </div>
              </a>
            </div>
          </Drawer>
        </div>
      }
    </>
  );
}
export default MuiDrawer

const useStyles = makeStyles({
  btn: {
    backgroundColor: ` #46734940 !important`,
    '& > svg': {
      color: `#467349 !important`,
    }
  }
})
